<!-- 服务费模板 -->
<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="运营方">
              <el-input v-model="searchForm.channelName" placeholder="请输入要搜索的运营方" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资方">
              <el-input v-model="searchForm.capName" placeholder="请输入要搜索的资方" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="核心企业">
              <el-input v-model="searchForm.coreName" placeholder="请输入要搜索的核心企业" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button type="primary" @click="$router.push({ name: 'serviceChargeTemplateAdd' })">
            创建模板
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="100">
            <template v-slot="scope">
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'serviceChargeTemplateEdit', query: { id: scope.row.id } })">
                编辑
              </el-link>
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'serviceChargeTemplateDetail', query: { id: scope.row.id } })">
                详情
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
  </el-main>
</template>
<script>
import { basePageListMixin } from '@/assets/js/mixins'
export default {
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        channelName: '',
        capName: '',
        coreName: ''
      },
      columnOption: [
        { label: '模板编号', prop: 'templateCode', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '资方', prop: 'capName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '核心企业', prop: 'coreName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '运营方', prop: 'channelName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建人', prop: 'createdBy', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
      ],
      loading: {
        list: false
      }
    }
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.serviceCharge.getTemplatePage(this.utils.filterTrimValue(this.searchForm)).then(res => {
        this.tableList = res.data.data || []
        this.loading.list = false
      }).finally(() => {
        // 解放loading
        this.loading.list = false
      })
    },
    editBeenOn (row) {
      const data = {
        id: row.id,
        productCode: row.productCode,
        state: 'BeenOn'
      }
      this.api.product.update({ info: data }).then(result => {
        if (result.data.success === true) {
          this.loading.list = false
          this.$message.success('已上架')
          this.getList()
        } else {
          this.loading.list = false
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.list = false
      })
    },
    editBeenOff (row) {
      const data = {
        id: row.id,
        productCode: row.productCode,
        state: 'BeenOff'
      }
      this.api.product.update({ info: data }).then(result => {
        if (result.data.success === true) {
          this.loading.list = false
          this.$message.success('已下架')
          this.getList()
        } else {
          this.loading.list = false
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.list = false
      })
    }
  }
}
</script>
<style scoped>
  .input_w80{
    width:100%;
  }
</style>
